/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, {useCallback, useState} from "react"
import {graphql, Link} from 'gatsby';
import SEO from "../components/seo";
import Layout from "../components/full-page/layout";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";
import classNames from "classnames";
import RichText from "prismic-reactjs/src/Component";
import {linkResolver} from "../utils/linkResolver";
import {serialize} from "../utils/htmlSerializers";
import CCLogoWhite from "../images/Claritycoat AF new.svg";
import FullPageCta from "../components/full-page/full-page-cta";
import {ColorThemeContextProvider} from "../contexts/colorThemeContext";

const PodcastEpisode = ({ data, pageContext: { lang } }) => {
  const [ episodeNav, setEpisodeNav ] = useState({
    showNotes: true,
    showTranscript: false,
  })

  const handleNavClick = useCallback(function (showNotes, showTranscript) {
    setEpisodeNav({
      showNotes: showNotes,
      showTranscript: showTranscript
    })
  }, []);

  const { prismicPodcastEpisode: { data: {
    title, published_on: publishedOn,
    buzzsprout_url: buzzsproutUrl, show_notes: showNotes, transcript,
    header_image: headerImage
  } } } = data;

  const slice = {
    primary: {
      heading: {
        text: title.text,
      },
      subheading: {
        html: '',
      },
      image: {
        url: headerImage.url,
      },
      image_mobile: {
        url: headerImage.url,
      },
      video_url: {
        url: null,
      },
      video_mobile_url: {
        url: null,
      },
      video_autoplay: false,
      video_muted: false,
      video_loop: false,
      video_playsinline: false,
      video_controls: false,
      theme: 'dark',
      // cta_1_title: 'Scoll for more',
      // cta_1_link: {
      //     url: null,
      // }
    }
  };


  return (
    <>
      <SEO title={title.text} />
      <ColorThemeContextProvider>
        <Layout
          disappearHeader={true}
          initColorTheme={'dark'}
          logo={ CCLogoWhite }
          topNav={data.prismicHome?.data.menu_links}
          footerNav={data.prismicHome?.data.footer_links}
          blogListing={data.allPrismicBlogPost?.nodes}
          currentLanguage={lang}
        >
          <FullPageCta slice={slice} />

          <section className="sub-content-block podcast-episodes">
            <div className="sub-content-block__item sub-content-block__item--text">
              <div>
                <p>
                  <Link to="../">‹ All episodes</Link>
                </p>

                <iframe
                  src={`${ buzzsproutUrl.embed_url }?client_source=small_player&iframe=true"`}
                  loading="lazy" width="100%" height="200" frameBorder="0" scrolling="no"
                  title={title.text} />

                <div className={"podcast-episode__nav"}>
                  <div className={classNames("podcast-episode__nav--item", {"podcast-episode__nav--item-current": episodeNav.showNotes})} onClick={(e) => handleNavClick(true, false)}>
                    Show Notes
                  </div>
                  <div className={classNames("podcast-episode__nav--item", {"podcast-episode__nav--item-current": episodeNav.showTranscript})} onClick={(e) => handleNavClick(false, true)}>
                    Transcript
                  </div>
                </div>

                <div style={{paddingTop: "30px"}}>
                  { episodeNav.showNotes &&
                    <RichText
                      render={showNotes['richText']}
                      linkResolver={linkResolver}
                      htmlSerializer={serialize}
                    />
                  }
                  { episodeNav.showTranscript &&
                    <RichText
                      render={transcript['richText']}
                      linkResolver={linkResolver}
                      htmlSerializer={serialize}
                    />
                  }
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </ColorThemeContextProvider>
    </>
  );
}

export const query = graphql`
    query PodcastEpisodeQuery($id: String, $lang: String) {
      prismicPodcastEpisode(id: {eq: $id}, lang: {eq: $lang}) {
        _previewable
        data {
          title {
            text
          }
          show_notes {
            richText
          }
          transcript {
            richText
          }
          published_on(formatString: "MMMM DD, YYYY")
          cover {
            alt
            url
          }
          header_image {
            alt
            url
          }
          buzzsprout_url {
            embed_url
          }
        }
      }
      
      prismicHome(lang: {eq: $lang}) {
        data {
          menu_links {
              menu_link {
                  url
                  type
              }
              name {
                text
              }
          }
          footer_links {
            footer_link {
             url
             type
            }
            name {
              text
            }
          }
        }
      }
      
      allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
    nodes {
      url
      data {
        title {
            text
        }
      }
    }
  }
    }
`

export default withPrismicPreview(PodcastEpisode);
