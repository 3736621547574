import React from 'react';
import classNames from "classnames";
import {PopupButton} from "@typeform/embed-react";
import Font from "../font";
import * as styles from './full-page.module.scss';
import RichText from "../../common/rich-text";
import Video from "../../common/video";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";


const PageCta = ({ heading, subheading, image, imageMobile, video_url, video_mobile_url, buttons,
                   colorTheme, autoPlay, muted, controls, playsInline, loop}) => {
  const { height } = useWindowSize();

  return (
    <div
      className={`full-page-section ${styles.bg} themed ${colorTheme ? colorTheme : ''}`}
      style={{
        height: height,
        position: 'relative',
      }}
    >
      {!image && video_url ? (
        <div
          style={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: -1,
            height: '100%',
            width: '-webkit-fill-available',
          }}
        >
          <Video
            url={video_url} url_mobile={video_mobile_url}
            autoPlay={autoPlay}
            muted={muted}
            controls={controls}
            playsInline={playsInline}
            loop={loop}
          />
        </div>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            height: '100%',
            width: '-webkit-fill-available',
          }}
        >
          <picture>
            <source media="(max-width: 575px)" srcSet={imageMobile} />
            <source media="(min-width: 576px)" srcSet={image} />
            <img src={image} style={{width: "100%", height: "100%", objectFit: "cover"}} alt={'full page bg image'}/>
          </picture>
        </div>
      )}
      {/*<div className={classNames(styles.wrapper, "fade")}>*/}
      <div className={classNames(styles.wrapper)}>
        <h1 className={`${styles.heading} theme-title`}>{heading}</h1>
        <div className={`${styles.subheading} theme-title`}>
          <RichText>{subheading}</RichText>
        </div>
        <div className={styles.buttonWrapper}>
          {buttons}
        </div>
      </div>
    </div>
  );
};

const FullPageCta = ({ slice }) => {
  const primaryData = slice.primary;

  return (
    <PageCta
      heading={primaryData.heading.text}
      subheading={primaryData.subheading.html}
      image={primaryData.image.url}
      imageMobile={primaryData.image_mobile.url ? primaryData.image_mobile.url : primaryData.image.url}
      video_url={primaryData.video_url.url}
      video_mobile_url={primaryData.video_mobile_url?.url}
      autoPlay={primaryData.video_autoplay}
      muted={primaryData.video_muted}
      loop={primaryData.video_loop}
      playsInline={primaryData.video_playsinline}
      controls={primaryData.video_controls}
      colorTheme={primaryData.theme}
      buttons={(
        <>
          {primaryData.cta_1_title && (
            primaryData.cta_1_link.url && primaryData.cta_1_link.url.includes('typeform.com') ? (
              <PopupButton id={primaryData.cta_1_link.url.substring(primaryData.cta_1_link.url.lastIndexOf('/') + 1)} className={`${styles.button}`}>
                {primaryData.cta_1_title}
              </PopupButton>
            ) : (
              <a
                className={styles.button}
                href={primaryData.cta_1_link.url}
              >
                {primaryData.cta_1_title}
              </a>
            )
          )}
          {primaryData.cta_2_title &&
            (
              primaryData.cta_2_link.url && primaryData.cta_2_link.url.includes('typeform.com') ? (
                <PopupButton id={primaryData.cta_2_link.url.substring(primaryData.cta_2_link.url.lastIndexOf('/') + 1)} className={classNames(styles.button, styles.buttonWhite)}>
                  {primaryData.cta_2_title}
                </PopupButton>
              ) : (
                <a
                  className={classNames(styles.button, styles.buttonWhite)}
                  href={primaryData.cta_2_link.url}
                >
                  {primaryData.cta_2_title}
                </a>
              )
            )}
        </>
      )}
    />
  );
};

export default FullPageCta;