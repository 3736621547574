import React from 'react';
import * as styles from './video.module.scss';
import {useWindowSize} from "../../../utils/hooks/useWindowSize";

const Video = ({ url, url_mobile=null, autoPlay=true, muted=true,controls=true,playsInline=true,loop=true }) => {
  const { width } = useWindowSize();

  return (
    <video
      className={styles.video}
      autoPlay={autoPlay === null ? true : autoPlay}
      muted={muted === null ? true : muted}
      controls={controls === null ? true : controls}
      playsInline={playsInline === null ? true : playsInline}
      loop={loop === null ? true : loop}
      src={width > 575 ? url : url_mobile ? url_mobile : url }
    />
  );
};

export default Video;